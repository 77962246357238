<template>
    <default-template
        title="事業者情報管理(事業者詳細）"
        :is-visible-public="true"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
        :is-visible-user-header="true"
    >
        
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">事業者情報管理</h2>
            </div>
            <error-list class="notice" />
            <div
                class="organization-box bl_organizationBox"
                v-show="!isInProgress"
            >
                <div class="title-and-button bl_organizationBox_grid1">
                    <h3>事業者情報</h3>
                </div>
                <div class="bl_organizationBox_grid2 ly_form_box">
                    <div class="formrow">
                        <app-label
                            name="string_type"
                            label="種別"
                        />
                        <p ref="jest-organization-type">{{organization.string_type}}</p>
                    </div>
                    <div class="formrow">
                        <app-label
                            name="name"
                            label="事業者名"
                        />
                        <p ref="jest-organization-name">{{organization.name}}</p>
                    </div>
                    <div class="formrow">
                        <app-label
                            name="postcode"
                            label="郵便番号"
                        />
                        <p ref="jest-organization-postcode">{{organization.postcode}}</p>
                    </div>
                    <div class="formrow">
                        <app-label
                            name="address"
                            label="所在地"
                        />
                        <p ref="jest-organization-address">{{organization.address}}</p>
                    </div>
                    <div
                        class="formrow"
                        v-if="organization.type == 1"
                    >
                        <app-label
                            name="belong_to"
                            label="所属団体"
                        />
                        <p ref="jest-organization-belong-to">{{organization.string_belong_to}}</p>
                    </div>
                    <div class="formrow uq_button_wrapper">
                        <app-button
                            name="事業者情報変更"
                            @click="editOrganization"
                            ref="jest-organization-edit"
                            v-if="user.is_organization_administrator"
                            class="inline hover_color__active"
                        />
                        <app-button
                            name="<span>ファイルアップロード用</span><span>パスワード変更</span>"
                            @click="moveTo('/organization/organization/zip_password/edit')"
                            ref="jest-organization-zip"
                            v-if="showChangeZipPassword"
                            class="uq_btnOrganizationZip inline"
                        />
                    </div>
                </div>
            </div>
            <div class="bl_userBox_wrapper">
                <div
                    v-for="(item, index) in users"
                    :key="'user' + index"
                    class="user-box bl_userBox"
                    v-show="!isInProgress"
                >
                    <div class="title-and-button bl_userBox_grid1">
                        <h3>利用者{{index + 1}}</h3>
                    </div>
                    <div class="bl_userBox_grid2 ly_form_box">
                        <div class="formrow">
                            <app-label
                                name="email"
                                label="メールアドレス"
                            />
                            <p :ref="'jest-organization-user-email' + index">{{item.email}}</p>
                        </div>
                        <div class="formrow">
                            <app-label
                                name="division"
                                label="担当部署"
                            />
                            <p :ref="'jest-organization-user-division' + index">{{item.division}}</p>
                        </div>
                        <div class="formrow">
                            <app-label
                                name="name"
                                label="担当者名"
                            />
                            <p :ref="'jest-organization-user-name' + index">
                                {{item.name}}
                                <span
                                    v-if="item.is_organization_administrator"
                                    class="admin"
                                >
                                    管理者
                                </span>
                            </p>
                        </div>
                        <div class="formrow">
                            <app-label
                                name="tel"
                                label="担当者電話番号"
                            />
                            <p :ref="'jest-organization-user-tel' + index">{{item.tel}}</p>
                        </div>
                        <div class="formrow uq_button_wrapper">
                            <app-button
                                name="変更"
                                @click="editUser(item)"
                                :ref="'jest-user-edit' + index"
                                v-if="showChangeButton(item.id)"
                                class="inline hover_color__active"
                            />
                            <app-button
                                name="削除"
                                @click="deleteUser(item)"
                                :ref="'jest-user-delete' + index"
                                class="delete inline hover_color__delete"
                                v-if="showDeleteButton(item.is_organization_administrator)"
                            />

                        </div>
                    </div>
                </div>
            </div>
            <div class="ly_buttonBox">
                <app-button
                    name="追加利用者の登録"
                    @click="createUser"
                    ref="jest-organization-user-create"
                    v-if="user.is_organization_administrator"
                    class="uq_adduser hover_color__active"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P120 事業者情報管理(事業者詳細）
 *
 */
import ErrorList from '@/components/molecules/ErrorList'
import { cloneDeep } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { move, defaultValue } from '@/util'
import Progress from '@/mixins/progress.js'

export default {
    name:'OrganizationsDetail',
    components:{
        ErrorList,
    },
    mixins:[Progress],
    async mounted(){
        const loader = this.$loading.show()
        this.startProgress()
        await Promise.all([
            this.getOrganization(),
            this.getOrganizationUsers()
        ])
        this.finishProgress()
        loader.hide()
    },
    computed:{
        ...mapGetters({
            user:'auth/user',
            organization:'auth/organization',
            users:'auth/users',
        }),
        isHouse(){
            return this.organization.type == 1
        },
        showChangeButton(){
            return function (id) {
                return this.user.is_organization_administrator || this.user.id === id
            }
        },
        /**
         * 削除ボタンの表示/非表示
         * 
         * @param {Boolean} isOrganizationAdministrator 表示されているユーザが機関の管理者か否か
         */
        showDeleteButton() {
            return function (isOrganizationAdministrator) {
                return this.user.is_organization_administrator && isOrganizationAdministrator === false
            }
        },
        showChangeZipPassword() {
            return this.user.is_organization_administrator && this.isHouse
        }
    },
    methods:{
        ...mapActions({
            getOrganization:'auth/getOrganization',
            getOrganizationUsers:'auth/getOrganizationUsers',
            update:'register/organization/update',
            updateUser:'register/user/update',
            clearError:'error/clear',
        }),
        moveTo(path){
            this.clearError()
            move(this.$router, path)
        },
        editOrganization(){
            this.update(this.organization)
            this.moveTo('/organization/organization/edit')
        },
        editUser(item){
            let userInformation = cloneDeep(item)
            this.$set(userInformation, 'email_confirmation', userInformation.email)
            this.updateUser(userInformation)
            this.moveTo('/organization/users/' + item.id + '/edit')
        },
        deleteUser(item){
            this.updateUser(item)
            this.moveTo('/organization/users/' + item.id + '/delete')
        },
        createUser(){
            this.updateUser(defaultValue.user)
            this.moveTo('/organization/users/create')
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 800px;
    max-width: 800px;
}
.ly_form_box{
    border:none;
    width: 100%;
}
.bl_container .bl_organizationBox{
    grid-template-columns: 120px 1fr;
}
.bl_organizationBox h3{
    margin: 3px 0 0;
}
div.uq_button_wrapper {
    display: flex;
    justify-content: space-between;
}
div.uq_button_wrapper .el_button{
    width: 50%;
    margin: 4px 8px 0 0;
}
.uq_btnOrganizationZip.el_button{
    border-radius: 22px;
    background-color: #505050;
}
.uq_btnOrganizationZip.el_button:hover{
    background-color: #757575;

}
.uq_btnOrganizationZip.el_button >>> span{
    white-space: nowrap;
}
.bl_userBox{
    display: grid;
    grid-template-columns: 120px 1fr;
    width: 100%;
    margin: 0 ;
}
.bl_userBox:not(:last-of-type){
    border-bottom: 1px solid #CDD6DD;
}
.bl_userBox_grid1{
    grid-column: 1;
}
.bl_userBox_grid1 >>> button{
    width: 180px;
    margin: 0 0 8px 0;
}
.bl_userBox:first-of-type h3{
    margin-top: 0;
}
.bl_userBox_grid2{
    grid-column: 2;
    padding: 16px 0;
}
.bl_userBox_grid2 p{
    min-width: 20vw;
}
.uq_adduser.el_button{
    display: inline-flex;
    align-items: center;
}
.uq_adduser.el_button:before{
    content: "+";
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 4px;
    margin-bottom: 2px;
    font-size: 16px;
    line-height: 16px;
    font-weight: bolder;
}
.ly_buttonBox{
    justify-content: flex-end;
    padding-right: 0;
}
.ly_buttonBox >>> .uq_adduser{
    margin-right: 0;
}
</style>