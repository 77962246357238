<template>
    <div class="form_box ly_form_box ly_userForm">
        <app-text
            name="old_zip_password"
            v-model="old_zip_password"
            :lbl_required="true"
            label="現在のパスワード"
            class="formrow"
            type="password"
            ref="jest-file-pass-old"
        />
        <app-text
            name="new_zip_password"
            v-model="new_zip_password"
            :lbl_required="true"
            label="新パスワード"
            class="formrow"
            type="password"
            ref="jest-file-pass-new"
        />
        <app-text
            name="new_zip_password_confirmation"
            v-model="new_zip_password_confirmation"
            :lbl_required="true"
            label="新パスワード確認"
            class="formrow"
            type="password"
            ref="jest-file-pass-new-conf"
        />
    </div>
</template>

<script>
/**
 * ZIPファイルパスワードの入力フォーム
 */
import { mapGetters, mapActions } from 'vuex'
import { mapComputedProperties } from '@/util'

export default {
    name:'FilePassForm',
    computed:{
        ...mapGetters({
            data:'register/organization/getAll',
        }),
        ...mapComputedProperties([
            'old_zip_password',
            'new_zip_password',
            'new_zip_password_confirmation',
        ])
    },
    mounted(){
        //入力を初期化
        this.clearZipPass()
    },
    methods:{
        ...mapActions({
            update:'register/organization/update',
            clearZipPass:'register/organization/clearZipPass'
        }),
    }
}
</script>

<style scoped>
</style>