<template>
    <default-template
        title="ファイルアップロード用パスワード変更"
        :is-visible-public="true"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
        :is-visible-user-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">ファイルアップロード用パスワード変更</h2>
                <hr class="hp_flexspacer"/>
                <p>機器データのアップロードの際に使用するファイルアップロード用のパスワードを変更します。<br />
                現在のパスワードが不明の場合は住宅性能評価・表示協会までご連絡ください。<br />
                現在パスワードが登録されていない場合は現在のパスワード欄を空白のままにしてください。</p>
            </div>
            <!-- 更新成功メッセージもエラーリストで表示するため遷移前に一瞬赤い文字で更新成功メッセージが見えるのを防ぐためのprogress -->
            <error-list v-show="!this.isInProgress" />
            <file-pass-form
            class="uq_organizationFilePassEdit" />
            <div v-show="!this.isInProgress" class="ly_buttonBox">
                <app-button
                    name="キャンセル"
                    @click="moveTo('/organization/organization')"
                    ref="jest-organization-file-pass-edit-cancel"
                    class="cancel"
                />
                <app-button
                    name="変更"
                    @click="submit"
                    ref="jest-organization-file-pass-edit-submit"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P129_ファイルパスワード変更画面
 *
 */
import ErrorList from '@/components/molecules/ErrorList'
import FilePassForm from '@/components/organisms/FilePassForm'
import { mapActions, mapGetters } from 'vuex'
import { move } from '@/util'
import Progress from '@/mixins/progress.js'

export default {
    name:'OrganizationFilePassEdit',
    components:{
        ErrorList,
        FilePassForm,
    },
    mixins:[Progress],
    computed:{
        ...mapGetters({
            organization:'auth/organization',
            prevalidateZip:'register/organization/prevalidateZip',
            prevalidateZipError:'register/organization/prevalidateZipError',
        }),
        isHouse(){
            return this.organization.type == 1
        },
    },
    methods:{
        ...mapActions({
            updateZipPass:'register/organization/updateZipPass',
            clearZipPass:'register/organization/clearZipPass',
            clearError:'error/clear',
            updateError:'error/update'
        }),
        moveTo(path){
            this.clearError()
            move(this.$router, path)
        },
        submit(){
            // 実行中は変更ボタンを非表示にする
            this.startProgress()
            this.clearError()
            // 新パスワードと新パスワード確認の一致をチェック
            if (this.prevalidateZip) {
                this.updateZipPass(this.organization.id)
                    .then(response => {
                        this.clearZipPass()
                        // 更新成功のメッセージの表示をする
                        this.updateError(response)
                        move(this.$router, '/organization/organization')
                        // 終了したらProgressを戻す
                        this.finishProgress()
                    }, reject => {
                        this.updateError(reject.response)
                        // 終了したらProgressを戻す
                        this.finishProgress()
                    })
            // 新パスワードが新パスワード確認と一致しない場合エラーを表示
            } else {
                this.updateError({
                    status:422,
                    data:{
                        errors:this.prevalidateZipError
                    }
                })
                // 終了したらProgressを戻す
                this.finishProgress()
            }
        },
    }
}
</script>

<style scoped>
.uq_organizationFilePassEdit >>>  div.formrow{
    grid-template-columns: 12em 1fr;
}
.bl_underHeader{
    width: 610px;
}
.ly_form_box{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.ly_buttonBox {
    margin-right: auto;
    margin-left: auto;
}
</style>