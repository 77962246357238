<template>
    <div class="form_box ly_form_box ly_organizationForm">
        <app-text
            name="name"
            v-model="name"
            text-box-id="organization_name"
            :lbl_required="true"
            label="事業者名称"
            class="formrow"
            v-if="!isUpdate"
        />
        <div
            v-if="isUpdate"
            class="formrow"
        >
            <app-label
                name="organization_name"
                label="事業者名称"
            />
            <p>{{ name }}</p>
        </div>
        <app-text
            name="postcode"
            v-model="postcode"
            text-box-id="organization_postcode"
            :lbl_required="true"
            label="郵便番号"
            class="formrow"
            ref="jest-organization-postcode"
        />
        <app-text
            name="address"
            v-model="address"
            :lbl_required="true"
            label="所在地"
            class="formrow address"
        />
        <app-text
            name="tel"
            v-model="tel"
            :lbl_required="true"
            label="代表電話番号"
            class="formrow"
        />
        <div
            v-if="showZipPass"
            class="formrow bl_input_container"
        >
            <app-label
                name="zip_password"
                label="ファイルアップロード用パスワード"
            />
            <div class="rowcontents">
                <input
                    type="password"
                    name="zip_password"
                    v-model="zip_password"
                    class="uq_pre_subscript"
                />
                <span class="uq_subscript">機器の登録を行う事業者のみ入力してください</span>
            </div>
        </div>
        <div
            v-if="showZipPass"
            class="formrow bl_input_container"
        >
            <app-label
                name="zip_password_confirmation"
                label="ファイルアップロード用パスワード(確認用)"
                class="rowlabel grid1"
            />
            <input
                type="password"
                name="zip_password_confirmation"
                v-model="zip_password_confirmation"
                class="rowcontents"
            />
        </div>
        <div
            v-if="isHouse"
            class="formrow"
        >
            <app-label
                name="belong_to"
                label="所属団体"
                class="rowlabel grid1"
            />
            <div class="rowcontents">
                <app-radio
                    v-for="(name, value) in master.belong_to"
                    :key="'belong_to' + value"
                    v-model="belong_to"
                    :value="value"
                    :label="name"
                />
            </div>
        </div>
        <!--ユーザ情報との一括登録の場合、ここにユーザ情報が入る-->
        <slot></slot>
        <div
            class="formrow"
            v-if="!isUpdate"
        >
            <app-label
                name="message"
                label="その他連絡事項等"
                class="rowlabel grid1"
            />
            <textarea
                name="message"
                v-model="message"
                class="rowcontents"
            />
        </div>
    </div>
</template>

<script>
/**
 * 事業者情報の入力フォーム
 *
 * [プロパティ]
 * isHouse ... 住宅版か否か
 */
import { mapGetters, mapActions } from 'vuex'
import { mapComputedProperties } from '@/util'
import master from '@/mixins/master'

export default {
    name:'OrganizationForm',
    props:{
        // 住宅版か否か
        isHouse:{
            type:Boolean,
            required:true,
        },
        // 更新か否か
        isUpdate:{
            type:Boolean,
            required:true,
        }
    },
    mixins:[master],
    computed:{
        ...mapGetters({
            data:'register/organization/getAll',
        }),
        ...mapComputedProperties([
            'name',
            'postcode',
            'address',
            'tel',
            'zip_password',
            'zip_password_confirmation',
            'belong_to',
            'message',
        ]),
        showZipPass(){
            return this.isHouse && this.isUpdate === false
        }
    },
    methods:{
        ...mapActions({
            update:'register/organization/update'
        })
    }
}
</script>

<style scoped>
.ly_form_box >>> div.common_text_column, .ly_form_box >>> div.formrow {
    grid-template-columns: 22em 1fr;
    -ms-grid-columns: 22em 1fr;
}

.ly_form_box >>> .ly_userForm{
  padding: 16px 0;
  border: none;
  grid-template-columns: 17em 1fr;
  -ms-grid-columns: 17em 1fr;
}
.uq_pre_subscript{
    margin-right: 16px;
}
.uq_subscript{
    white-space: nowrap;
}
</style>