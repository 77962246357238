<template>
    <div class="form_box ly_form_box ly_organizationForm">
        <div class="formrow">
            <app-label
                name="name"
                label="事業者名称"
                class=""
            />
            <p ref="jest-organization-name">{{name}}</p>
        </div>
        <div class="formrow">
            <app-label
                name="postcode"
                label="郵便番号"
                class=""
            />
            <p ref="jest-organization-postcode">{{postcode}}</p>
        </div>
        <div class="formrow">
            <app-label
                name="address"
                label="所在地"
                class=""
            />
            <p ref="jest-organization-address">{{address}}</p>
        </div>
        <div class="formrow">
            <app-label
                name="tel"
                label="代表電話番号"
                class=""
            />
            <p ref="jest-organization-tel">{{tel}}</p>
        </div>
        <div
            v-if="showZipPass"
            class="formrow"
        >
            <app-label
                name="zip_password"
                label="ファイルアップロード用パスワード"
            />
            <p>{{maskedZipPassword}}</p>
        </div>
        <div
            v-if="isHouse"
            class="formrow"
        >
            <app-label
                name="belong_to"
                label="所属団体"
            />
            <p>{{master.belong_to[belong_to]}}</p>
        </div>
        <!--ユーザ情報との一括登録の場合、ここにユーザ情報が入る-->
        <slot></slot>
        <div
            class="formrow"
            v-if="!isUpdate"
        >
            <app-label
                name="message"
                label="その他連絡事項等"
                class=""
            />
            <p>{{message}}</p>
        </div>
    </div>
</template>

<script>
/**
 * 事業者情報の入力内容確認
 *
 * [プロパティ]
 * isHouse ... 住宅版か否か
 *
 * [算出プロパティ]
 * maskedZipPassword ... 文字数をzip_passwordと合わせた「*」の羅列
 */
import { mapGetters, mapActions } from 'vuex'
import { mapComputedProperties } from '@/util'
import master from '@/mixins/master'

export default {
    name:'OrganizationConfirm',
    props:{
        // 住宅版か否か
        isHouse:{
            type:Boolean,
            required:true,
        },
        // 更新か否か
        isUpdate:{
            type:Boolean,
            required:true,
        }
    },
    mixins:[master],
    computed:{
        ...mapGetters({
            data:'register/organization/getAll',
        }),
        ...mapComputedProperties([
            'name',
            'postcode',
            'address',
            'tel',
            'zip_password',
            'belong_to',
            'message',
        ]),
        // 文字数をzip_passwordと合わせた「*」の羅列
        maskedZipPassword(){
            return '*'.repeat(this.zip_password.length)
        },
        showZipPass(){
            return this.isHouse && this.isUpdate === false
        }
    },
    methods:{
        ...mapActions({
            update:'register/organization/update'
        })
    }
}
</script>

<style scoped>
</style>