<template>
    <default-template
        title="事業者情報変更"
        :is-visible-public="true"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
        :is-visible-user-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">事業者情報変更</h2>
                <hr class="hp_flexspacer"/>
                <p>変更内容を入力してください。事業者名は変更できません。<br />事業者名を変更したい場合は住宅性能評価・表示協会までご連絡ください。</p>
            </div>
            <error-list />
            <organization-form
                :is-house="isHouse"
                :is-update="true"
                class="uq_organizationEdit"
            />
            <div class="ly_buttonBox">
                <app-button
                    name="キャンセル"
                    @click="moveTo('/organization/organization')"
                    ref="jest-organization-edit-cancel"
                    class="cancel"
                />
                <app-button
                    name="入力内容確認"
                    @click="moveTo('/organization/organization/edit/confirm')"
                    ref="jest-organization-edit-confirm"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P121 事業者情報変更
 *
 */
import ErrorList from '@/components/molecules/ErrorList'
import OrganizationForm from '@/components/organisms/OrganizationForm'
import { mapActions, mapGetters } from 'vuex'
import { move } from '@/util'

export default {
    name:'OrganizationEdit',
    components:{
        ErrorList,
        OrganizationForm,
    },
    mounted(){
        scroll(0, 0)
        if(document.getElementById('organization_postcode') != null){
            document.getElementById('organization_postcode').focus()
        }
    },
    computed:{
        ...mapGetters({
            organization:'auth/organization',
        }),
        isHouse(){
            return this.organization.type === 1
        }
    },
    methods:{
        ...mapActions({
            clearError:'error/clear',
        }),
        moveTo(path){
            this.clearError()
            move(this.$router, path)
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 800px;
    max-width: 800px;
}
.uq_organizationEdit >>>  div.formrow{
    grid-template-columns: 10em 1fr;
}
</style>